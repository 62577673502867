
import Teles from './media/images/shijuka.png';
import Bernard from './media/images/bernard.png';
import sampleAPose from './media/images/Sample-A-Pose-Male.png';
import Lowerbody from './media/images/lowerbody.png';
import Legsbelowthigh from './media/images/bothleg.png';
import Leftlegbelowthigh from './media/images/singleleg.png';
import Rightfoot from './media/images/foot.png';


const SampleModels = {
    sampleModelsJson: [
        {
            "id": "000",
            "modelLabel": "Teles",
            "modelUrl": Teles,
            "ModelType": "glb"
        },
        {
            "id": "001",
            "modelLabel": "A-pose male",
            "modelUrl": sampleAPose,
            "ModelType": "glb"
        },
        {
            "id": "002",
            "modelLabel": "Bernard",
            "modelUrl": Bernard,
            "ModelType": "glb"
        },
        {
            "id": "003",
            "modelLabel": "Lower-body",
            "modelUrl": Lowerbody,
            "ModelType": "glb"
        },
        {
            "id": "004",
            "modelLabel": "Both-Legs",
            "modelUrl": Legsbelowthigh,
            "ModelType": "glb"
        },
        {
            "id": "005",
            "modelLabel": "Single-Leg",
            "modelUrl": Leftlegbelowthigh,
            "ModelType": "glb"
        },
        {
            "id": "006",
            "modelLabel": "Foot",
            "modelUrl": Rightfoot,
            "ModelType": "glb"
        },        
    ]
}

export { SampleModels };