import axios from "axios";
import { getDataFromRegion, BASE_URL, STAGE, getBaseUrl } from "../../Config";

// Function to fetch user details from the customer API endpoint
export const getUserDetails = (user) => {
  const customerUrl = getDataFromRegion().custUrl;
  const stage = "prod";
  const customerEndpoint = `/customer/email?email=${user.email}`;
  const url = `${customerUrl}${stage}${customerEndpoint}`;

  return axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data;
        let jsonBody = JSON.parse(data.body);
        const uProfile = {
          appKey: jsonBody.key,
          appKeyId: jsonBody.keyId,
          planType: jsonBody.type,
          verified: jsonBody.verified,
          block: jsonBody.block,
          enable_status: jsonBody.enable_status,
        };
        return uProfile;
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
      // Re-throw the error with additional details
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        throw new Error(`Server responded with status: ${error.response.status}, message: ${error.response.data}`);
      } else if (error.request) {
        // The request was made but no response was received
        throw new Error('No response received from server');
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(`Request setup error: ${error.message}`);
      }
    });
};

// Function to fetch data from the getsavedmodels API endpoint
export const getSavedModelsList = async (uProfile) => {
  const url = `${BASE_URL}${STAGE}/models/getsavedmodels`;
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": uProfile.appKey,
      },
    });

    if (response.status === 200) {
      const result = JSON.parse(response.data);
      const savedModels = result.filter(element => element.data.caption != null && element.data.caption !== "");
      return savedModels;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching data:", error);

    if (error.response) {
      // Server responded with a status code outside the range of 2xx
      throw new Error(`Server responded with status: ${error.response.status}, message: ${error.response.data}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error('Request data:', error.request);
      throw new Error('No response received from server');
    } else {
      // Error in setting up the request
      console.error('Error message:', error.message);
      throw new Error(`Request setup error: ${error.message}`);
    }
  }
};

export const getSavedModelUrl = (id, uProfile) => {
  const url = BASE_URL + STAGE + "/models/getsavedmodelurl";
  const body = {
    fileId: id,
  };

  return axios
    .post(url, body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": uProfile.appKey,
      },
    })
    .then((response) => {
      let result = JSON.parse(response.data);
      let fileurl = result.url;
      return fileurl;
    })
    .catch((error) => {
      // Handle error
      console.error(error);
      throw error;
    });
};

export const uploadModel = (fileName, fileId, uProfile) => {
  let body = {
    fileurl: fileName,
    fileId: fileId,
    appKeyId: uProfile.appKeyId,
  };

  return axios
    .post(getBaseUrl() + STAGE + "/models/studioupload", body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": uProfile.appKey,
      },
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      // Handle error
      console.error(error);
      throw error;
    });
};

export const callMeasureAPI = (fileUrl, fileType, uProfile) => {

  let body = {
    fileurl: fileUrl,
    filetype: fileType,
    auto_align: true
  };

  return axios
    .post(BASE_URL + STAGE + "/models/measure", body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": uProfile.appKey,
      },
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      // Handle error
      console.error(error);
      throw error;
    });
};

// Function to delete data from the deletesavedmodel API endpoint
export const deleteSavedModelData = async (id, uProfile) => {
  const url = `${BASE_URL}${STAGE}/models/deletesavedmodel`;
  const body = {
    fileId: id,
  };

  try {
    const response = await axios.delete(url, {
      data: body,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": uProfile.appKey,
      },
    });

    if (response.status === 200) {
      console.log('Model data deleted successfully');
      return response.data; // Return the response data if needed
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error deleting model data:', error);

    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range
      throw new Error(`Server responded with status: ${error.response.status}, message: ${error.response.data}`);
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response received from server');
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error(`Request setup error: ${error.message}`);
    }
  }
};

// Function to delete all the data from the deleteall endpoint
export const deleteAllData = async (uProfile) => {
  const url = `${BASE_URL}${STAGE}/models/deleteall`;

  try {
    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": uProfile.appKey,
      },
    });

    if (response.status === 200) {
      console.log('Model data deleted successfully');
      return response.data; // Return the response data if needed
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error deleting model data:', error);

    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range
      console.error('Server responded with an error status:', error.response.status);
      console.error('Response data:', error.response.data);
      throw new Error(`Server responded with status: ${error.response.status}, message: ${error.response.data}`);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received from the server:', error.request);
      throw new Error('No response received from the server');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Request setup error:', error.message);
      throw new Error(`Request setup error: ${error.message}`);
    }
  }
};