import React, { useState, useEffect, useRef } from 'react';
import '../../styles/Header.css';

import MeasureUpLogo from '../../media/3dmuLogo.png';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SettingsIcon from '@material-ui/icons/Settings';

const Index = (props) => {

  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
  const [settingsPopupOpened, setSettingsPopupOpened] = useState(false);
  const [userPopupOpened, setUserPopupOpened] = useState(false);
  const userDropdownRef = useRef(null);
  const settingsDropdownRef = useRef(null);
  const settingsIconRef = useRef(null);
  

  useEffect(() => {
    // Add event listener to handle clicks outside the dropdowns
    const handleClickOutside = (event) => {
      if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
        setShowUserDropdown(false);
      }
      if (settingsDropdownRef.current && !settingsDropdownRef.current.contains(event.target)) {
        setShowSettingsDropdown(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener('mouseup', handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSettingsPopupOpened(showSettingsDropdown);
    if(showSettingsDropdown && settingsIconRef.current && settingsDropdownRef.current) {
      const buttonRect = settingsIconRef.current.getBoundingClientRect();
      const dropdownWidth = settingsDropdownRef.current.offsetWidth;

      let newPosition = {
          left: buttonRect.right - dropdownWidth // 10px offset
      };

      // Adjust position if dropdown overflows at the top
      if (newPosition.top < 0) {
          newPosition.top = 10; // Move to a minimum distance from the top edge
      }

      settingsDropdownRef.current.style.left = newPosition.left + 'px';
      settingsDropdownRef.current.style.width = `${dropdownWidth}px`; // Maintain the width
    }
  }, [showSettingsDropdown]);

  useEffect(() => {
    setUserPopupOpened(showUserDropdown);
  }, [showUserDropdown]);

  const toggleUserDropDown = () => {
    if (userPopupOpened) {
      setShowUserDropdown(false);
    } else {
      setShowUserDropdown(true);
    }
  };

  const toggleSettingsDropDown = () => {
    if (settingsPopupOpened) {
      setShowSettingsDropdown(false);
    } else {
      setShowSettingsDropdown(true);
    }
  };

  const redirectTo3dmuWeb = () => {
    // Set the URL you want to redirect to
    const newWindow = window.open('https://3dmeasureup.ai/', '_blank');
    newWindow.focus(); // Optional: Focus the new window
  };

  const handleSignOut = () => {
    props.handleLogOut();
  };

  return (
    <>
      <div className='main_header'>
        <div className='header__logo'>
          <img src={MeasureUpLogo} onClick={redirectTo3dmuWeb} alt='3DMU-LOGO' />
        </div>

        <div className='header__icons'>
          <span >
            {/* <HelpOutlineIcon /> */}
            {/* <SettingsIcon  ref={settingsIconRef} onClick={toggleSettingsDropDown} /> */}
          </span>
          <img
            src={props.userPhoto}
            title={props.userName}
            data-position="bottom center"
            onClick={toggleUserDropDown}
            alt='User Name'
          />
        </div>

        {showUserDropdown && (
          <div className='header__logo__dropdown' ref={userDropdownRef}>
            <a onClick={handleSignOut}>Sign Out</a>
          </div>
        )}

        {showSettingsDropdown && (
          <div className='header__logo__dropdown' ref={settingsDropdownRef}>
            <a>Help</a>
            <a>Send Feedback</a>
          </div>
        )}
      </div>
    </>
    
  );
};

export default Index;
