import React from 'react'
import '../../styles/SidebarItem.css'

import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const SidebarItem = ({ arrow, icon, label, onClick, disabled, selected }) => {

    const itemStyle = {
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? 0.7 : 1,
        backgroundColor: selected ? '#C2E7FF' : 'auto',
        transition: selected ? 'background-color 0s' : 'auto'// This removes any transition effects on hover
    };

    return (
        <div className='sidebarItem' onClick={!disabled ? onClick : null} style={itemStyle}>
            <div className='sidebarItem__main'>
                {icon}
                <p>{label}</p>
            </div>
        </div>

    )
}

export default SidebarItem