//*****************************************//
const STAGE = 'prod';
let BASE_URL_OPTIONS = {
    DEBUG: getDataFromRegion().DEBUG,
    LIVE: getDataFromRegion().LIVE,
    STAGING: getDataFromRegion().STAGING,
    W_DEBUG: getDataFromRegion().W_DEBUG,
    W_LIVE: getDataFromRegion().W_LIVE,
    W_STAGING: getDataFromRegion().W_STAGING,
}

// Set the BASE_URL to DEBUG  and STORAGE_SERVICE to MINIO While setting up IN-PREMISE Version.
const BASE_URL = BASE_URL_OPTIONS.STAGING;
const W_BASE_URL = BASE_URL_OPTIONS.W_STAGING;

function getDataFromRegion() {
    //Select specific Region by commiting ans uncommiting the REGION
    //Ensure the front end and backend REGION should be same.
    let REGION = "MUMBAI";
    // let REGION = "SYDNEY";

    let API = {};

    if (REGION === "MUMBAI") {
        // MUMBAI REGION DATA
        API.DEBUG = "http://localhost:7589/";
        API.W_DEBUG = 'http://localhost:6590/';
        API.LIVE = "https://api.3dmu.prototechsolutions.com/";
        API.W_LIVE = "https://3dmu-app.prototechsolutions.com/"
        API.STAGING = "https://api-staging.3dmu.prototechsolutions.com/";
        API.W_STAGING = "https://3dmu-app-staging.prototechsolutions.com/"
        API.pricingUrl = "https://3dmeasureup.com/pricing";
        API.shijukaFileUrl = "https://3dmu-development-space.s3.ap-south-1.amazonaws.com/models/default/shijuka.glb";
        API.keyVerify = "https://789i08evtl.execute-api.ap-south-1.amazonaws.com/v1/verifykey";
        API.getUsagebyMonth = " https://cust.3dmu.prototechsolutions.com/prod/customer/getusage?month=";
        API.custUrl = "https://cust.3dmu.prototechsolutions.com/";
        return API;
    } else if (REGION === "SYDNEY") {
        //SYDNEY REGION DATA
        API.DEBUG = "http://localhost:7589/";
        API.W_DEBUG = 'http://localhost:6590/';
        API.LIVE = "https://api-aussyd.3dmu.prototechsolutions.com/";
        API.W_LIVE = "https://3dmu-webapp-aussyd.prototechsolutions.com/"
        API.STAGING = "https://api-aussyd.3dmu.prototechsolutions.com/";
        API.W_STAGING = "https://3dmu-webapp-aussyd.prototechsolutions.com/"
        API.pricingUrl = "https://3dmeasureup.com/pricing";
        API.shijukaFileUrl = "https://3dmu-development-space.s3.ap-south-1.amazonaws.com/models/default/shijuka.glb";
        API.keyVerify = "https://789i08evtl.execute-api.ap-south-1.amazonaws.com/v1/verifykey";
        API.getUsagebyMonth = " https://cust.3dmu.prototechsolutions.com/prod/customer/getusage?month=";
        API.custUrl = "https://cust.3dmu.prototechsolutions.com/";
        return API;
    }
}

function getBaseUrl() {
    let baseURL = BASE_URL;
    if (baseURL === BASE_URL_OPTIONS.DEBUG) {
        baseURL = BASE_URL_OPTIONS.STAGING;
    }
    return baseURL;
}

export {BASE_URL, W_BASE_URL, STAGE, BASE_URL_OPTIONS, getDataFromRegion, getBaseUrl}