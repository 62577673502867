import React, { useState, useEffect } from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';

import Header from './components/header';
import Sidebar from './components/sidebar';
import FilesView from './components/filesView/FilesView';
import SideIcons from './components/sideIcons';
import { auth, provider } from "./firebase";
import LoginLogo from './media/splash1.png';
import { getUserDetails, getSavedModelsList } from './components/utility/apiUtils';
import SampleModelBar from './components/SampleModelBar';

function App() {
  const [user, setUser] = useState(null);
  const [userProfile, setUserProfile] = useState({});
  const [savedFiles, setSavedFiles] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [unAuthorizedErrorModalOpen, setUnAuthorizedErrorModalOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedEmail = urlParams.get('el');
    const photoURL = urlParams.get('pL');

    if (encryptedEmail && !urlParams.has('wp')) {
      let email = decodeURIComponent(encryptedEmail);
      try {
        email = atob(email);
        const userDetails = {
          email: email,
          photoURL: photoURL
        };
        setUser(userDetails);
      } catch (error) {
        setUser(null);
        setUserProfile({});
        setSavedFiles(null);
      }
    } else {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          setUser(user);
        } else {
          setUser(null);
        }
      });
  
      // Cleanup the listener when component unmounts
      return () => unsubscribe();
    }

  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('el');
    const password = urlParams.get('wp');
  
    if (email && password) {
      const decryptedEmail = atob(decodeURIComponent(email));
      const decryptedPassword = atob(decodeURIComponent(password));
  
      auth.signInWithEmailAndPassword(decryptedEmail, decryptedPassword)
        .then((userCredential) => {
          console.log("Login Successful");
          setUser(userCredential.user);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (user) {
      getUserDetails(user)
        .then(response => {
          if (response) {
            setUserProfile(response);
          } else {
            // Handle login error with a meaningful message
            console.error('Error Getting User details, Additional details:');
            setErrorModalOpen(true);
          }
        })
        .catch(error => {
          // Handle different types of errors
          console.error('Error Getting User details, Additional details:', error);
          if (error.message.includes('status: 403')) {
            setUnAuthorizedErrorModalOpen(true);
          } else if (error.message.includes('status: 404')) {
            setUnAuthorizedErrorModalOpen(true);
          } else if (error.message.includes('No response received from server')) {
            // TO DO : HANDLE ERROR PROPERLY
            setErrorModalOpen(true);
          } else{
            setErrorModalOpen(true);
          }
        });
    }
  }, [user]);

  useEffect(() => {
    if (Object.keys(userProfile).length > 0) {
      getSavedModelsList(userProfile)
      .then(response => {
        setSavedFiles(response);
      })
      .catch(error => {
        console.error('Error fetching saved files, Additional details:', error);
        if (error.message.includes('status: 403')) {
          setUnAuthorizedErrorModalOpen(true);
        } else if (error.message.includes('No response received from server')) {
          // TO DO : HANDLE ERROR PROPERLY
          setUnAuthorizedErrorModalOpen(true);
        } else {
          // TO DO : HANDLE ERROR PROPERLY
          setErrorModalOpen(true);
        }
      });
    }
  }, [userProfile]);

  const handleLogin = async () => {
    if (!user) {
      await auth.signInWithPopup(provider)
        .then(result => {
          setUser(result.user);
        })
        .catch(error => {
          alert(error.message);
        });
    }
  };

  const handleLogOut = () => {
    auth.signOut().then(() => {
      setUser(null);
      setUserProfile({});
      setSavedFiles(null);
    }).catch((err) => alert(err.message))
  }

  const updateSavedFiles = (file, action) => {
    if (action === 'add') {
      setSavedFiles([file, ...savedFiles]); // Prepend the new file to the array
    } else if (action === 'remove') {
      setSavedFiles(savedFiles.filter(savedFile => savedFile.id !== file.id)); // Remove the file from the array
    } else {
      setSavedFiles(file); //Remove all files from the array
    }
  };

  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
    handleLogOut()
  };

  const handleCloseUnAuthorizedErrorModal = () => {
    setUnAuthorizedErrorModalOpen(false)
    handleLogOut()
  };

  return (
    <div className="app">
      {user ? (
        <>
          <Header 
            userName={user ? user.displayName : ''} 
            userPhoto={user ? user.photoURL : ''} 
            handleLogOut={handleLogOut} 
          />
          {savedFiles ? (
            <>
              <div className="app__main">
                <Sidebar docArr={savedFiles} uProfile={userProfile} updateSavedFiles={updateSavedFiles} setErrorModalOpen={setErrorModalOpen} setUnAuthorizedErrorModalOpen={setUnAuthorizedErrorModalOpen} />
                <div className="main__content">
                  <SampleModelBar />
                  <FilesView docArr={savedFiles} uProfile={userProfile} updateSavedFiles={updateSavedFiles}/>
                </div>
                <SideIcons />
              </div>
            </>
          ) : (
            <div>
              <Dimmer active>
                <Loader size='large' content='Preparing files' />
              </Dimmer>
            </div>
          )}
        </>
      ) : (
        <div className='app__login'>
          <img src={LoginLogo} alt="3D Measure Up Studio" />
          <button onClick={handleLogin}><b>Sign in to 3D Measure Up Studio</b></button>
          <p>By continuing, you are indicating that you accept our <a href="https://3dmeasureup.com/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a> and <a href="https://3dmeasureup.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>.</p>
        </div>
      )}

      <Modal
        size={"tiny"}
        open={unAuthorizedErrorModalOpen}
        onClose={() => handleCloseUnAuthorizedErrorModal(false)}
        closeOnDimmerClick={false}
        header='Access Denied'
        content='You are not authorized to access this resource, Please contact: 3dmu.support@prototechsolutions.com'
        actions={[{ key: 'close', content: 'Close', negative: true, onClick: () => handleCloseUnAuthorizedErrorModal(false) }]}
      />

      <Modal
        size={"tiny"}
        open={errorModalOpen}
        onClose={() => handleCloseErrorModal(false)}
        closeOnDimmerClick={false}
        header='oops something went wrong'
        content='Please try again later or contact: 3dmu.support@prototechsolutions.com'
        actions={[{ key: 'close', content: 'Close', negative: true, onClick: () => handleCloseErrorModal(false) }]}
      />

    </div>
  );
}

export default App;
